import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanMatchFn, Router, UrlSegment } from '@angular/router';

import { AuthStore } from '@@shared/stores/auth-store/stores/auth.store';


export const roleMatchGuard: CanMatchFn = (route: ActivatedRouteSnapshot, segments: UrlSegment[]) => {
	const authStore = inject(AuthStore);
	const router = inject(Router);
	const userRole: string = authStore.getUserRole();

	if (!route.data?.roles) {
		return true;
	}

	if (userRole && (authStore.isSealightsAdminUser() || (route.data.roles as string[]).includes(userRole))) {
		return true;
	}

	return router.createUrlTree(['/']);
};
